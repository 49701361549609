.halloweenBannerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: black;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  height: 44px;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    height: 76px;
    padding-right: 26px;
    background-repeat: no-repeat;
    background-position: -90%;
    background-image: url('./assets/background.svg');
  }

  @media (min-width: 1024px) {
    height: 90px;
    padding-right: 15px;
    background-position: 580%;
  }

  @media (min-width: 1440px) {
    height: 95px;
    padding-right: 20px;
    background-position: 38%;
  }
}

.rightSideContainer {
  display: flex;
  align-items: center;
}

.pumpkin {
  position: absolute;
  width: 40px;
  height: 40px;
  rotate: 27deg;
  top: 2px;
  right: -10px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
    top: -9px;
    left: -30px;
  }

  @media (min-width: 1024px) {
    width: 110px;
    height: 110px;
    top: -24px;
  }
}

.pumpkinDesktopContainer {
  display: none;

  @media (min-width: 1440px) {
    display: block;
    position: relative;
  }
}

.pumpkinBackground {
  display: none;
  position: absolute;
  left: 0;
  top: 0;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1024px) {
    scale: 1.3;
    top: 4px;
    left: 12px;
  }
}

.pumpkinDesktop {
  position: absolute;
  width: 90px;
  height: auto;
  transform: scaleY(-1) rotate(195deg);
  right: 30px;
}

.mainText {
  font-size: 10px;
  font-weight: 500;
  position: relative;
  padding-left: 6px;

  @media (min-width: 768px) {
    font-size: 16px;
    padding-left: 54px;
    letter-spacing: -0.3px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    padding-left: 100px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    padding-left: 86px;
  }
}

.mainTextDelimeter {
  @media (min-width: 768px) {
    display: none;
  }
}

.subText {
  display: none;
  font-weight: 400;

  @media (min-width: 768px) {
    margin-top: 8px;
    display: block;
    font-size: 12px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
  }
}

.timer {
  display: flex;
  gap: 8px;

  @media (min-width: 768px) {
    gap: 14px;
  }

  @media (min-width: 1024px) {
    gap: 30px;
  }

  @media (min-width: 1440px) {
    margin-right: 80px;
  }
}

.timerNumber {
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
  }
}

.timerText {
  font-size: 6px;
  opacity: 0.6;

  @media (min-width: 768px) {
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }
}

.timerNuberContainer {
  @media (min-width: 768px) {
    display: grid;
    gap: 9px;
  }
}

.getDiscountButton {
  background: var(--beta);
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 8px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  @media (min-width: 768px) {
    padding: 14px 19px;
    font-size: 12px;
    margin-left: 27px;
    margin-right: 21px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    padding: 13px 22px;
    margin-left: 35px;
    margin-right: 31px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
    padding: 18px 40px;
    margin-left: 20px;
    margin-right: 35px;
  }
}

.getDiscountButton:hover {
  background: #005bb5;
}

.closeButton {
  background: rgba(255, 255, 255, 0.2);
  color: black;
  font-size: 6px;
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 10px;
    width: 16px;
    height: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }
}

.closeButton:hover {
  background: rgba(255, 255, 255, 0.3);
}
