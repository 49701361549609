.BlackFridayBannerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: black;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  height: 56px;
  overflow: hidden;
  position: relative;
  background-image: url('./assets/background.svg');
  background-size: cover;
  position: sticky;
  top: -1px;
  z-index: 9999;

  @media (min-width: 768px) {
    height: 76px;
    padding-right: 26px;
    background-repeat: no-repeat;
    background-position: -80%;
    background-size: auto;
  }

  @media (min-width: 1024px) {
    height: 90px;
    padding-right: 15px;
    background-position: 500%;
  }

  @media (min-width: 1440px) {
    height: 104px;
    padding-right: 20px;
    background-position: 28%;
  }
}

.rightSideContainer {
  display: flex;
  align-items: center;
}

.starDiscountDesktopContainer {
  display: none;

  @media (min-width: 1440px) {
    display: block;
    position: relative;
  }
}

.starDiscount {
  display: none;
  position: absolute;

  @media (min-width: 768px) {
    display: block;
    width: 88px;
    height: auto;
    top: 12px;
    left: -30px;
  }

  @media (min-width: 1024px) {
    top: 11px;
    left: -37px;
    width: 110px;
  }

  @media (min-width: 1440px) {
    top: 8px;
    left: -47px;
    width: 133px;
  }
}

.starDiscountDesktop {
  position: absolute;
  width: 104px;
  height: auto;
  right: -45px;
  top: -85px;
}

.mainText {
  font-size: 10px;
  font-weight: 500;
  position: relative;
  padding-left: 6px;

  @media (min-width: 768px) {
    font-size: 16px;
    padding-left: 54px;
    letter-spacing: -0.3px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    padding-left: 100px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    padding-left: 86px;
  }
}

.mainTextDelimeterFirst {
  @media (min-width: 1024px) {
    display: none;
  }
}

.mainTextDelimeterSecond {
  @media (min-width: 768px) {
    display: none;
  }
}

.subText {
  display: none;

  @media (min-width: 1024px) {
    margin-top: 8px;
    display: block;
    font-size: 14px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
    margin-top: 15px;
  }
}

.timer {
  display: flex;
  gap: 5px;

  @media (min-width: 768px) {
    gap: 14px;
    margin-right: 29px;
  }

  @media (min-width: 1024px) {
    gap: 18px;
    margin-right: 0;
  }

  @media (min-width: 1440px) {
    margin-right: 50px;
  }
}

.timerNumber {
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
  }
}

.timerText {
  font-size: 6px;
  opacity: 0.6;

  @media (min-width: 768px) {
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }
}

.timerNuberContainer {
  display: grid;
  gap: 3px;

  @media (min-width: 768px) {
    gap: 9px;
  }
}

.timerColon {
  margin-right: 3px;

  @media (min-width: 768px) {
    margin-right: 6px;
  }
}

.getDiscountButton {
  background: var(--beta);
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 8px !important;
  padding: 10px 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;

  @media (min-width: 768px) {
    padding: 11px 32px !important;
    font-size: 12px !important;
    margin-left: 27px !important;
    margin-right: 21px !important;
  }

  @media (min-width: 1024px) {
    font-size: 14px !important;
    padding: 11px 22px !important;
    margin-left: 21px !important;
    margin-right: 22px !important;
  }

  @media (min-width: 1440px) {
    font-size: 16px !important;
    padding: 16px 40px !important;
    margin-left: 58px !important;
    margin-right: 35px !important;
  }
}

.getDiscountButton:hover {
  background-color: var(--beta) !important;
}

.closeButton {
  background: rgba(255, 255, 255, 0.2);
  color: black;
  font-size: 6px;
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 10px;
    width: 16px;
    height: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }
}

.closeButton:hover {
  background: rgba(255, 255, 255, 0.3);
}

.ligthning {
  width: 17px;
  height: auto;
  rotate: 199deg;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  @media (min-width: 1440px) {
    display: block;
    width: 23px;
  }
}
