/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@font-face {
font-family: '__golosTextVF_099e87';
src: url(/_next/static/media/0219ce3abed241c2.p.woff2) format('woff2');
font-display: optional;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__golosTextVF_099e87';
src: url(/_next/static/media/639d7b0e528377cc.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__golosTextVF_099e87';
src: url(/_next/static/media/c0183fdbcd16a152.p.woff2) format('woff2');
font-display: optional;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__golosTextVF_099e87';
src: url(/_next/static/media/0fffe0a954f8ee5c.p.woff2) format('woff2');
font-display: optional;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__golosTextVF_099e87';
src: url(/_next/static/media/e6cab9efb3c6bd66.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__golosTextVF_099e87';
src: url(/_next/static/media/94cb635cdcefc1d0.p.woff2) format('woff2');
font-display: optional;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__golosTextVF_Fallback_099e87';src: local("Arial");ascent-override: 90.05%;descent-override: 20.21%;line-gap-override: 0.00%;size-adjust: 108.83%
}.__className_099e87 {font-family: '__golosTextVF_099e87', '__golosTextVF_Fallback_099e87'
}

.HalloweenBanner_halloweenBannerWrapper__JTevD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: black;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  height: 44px;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    height: 76px;
    padding-right: 26px;
    background-repeat: no-repeat;
    background-position: -90%;
    background-image: url(/_next/static/media/background.9068a56e.svg);
  }

  @media (min-width: 1024px) {
    height: 90px;
    padding-right: 15px;
    background-position: 580%;
  }

  @media (min-width: 1440px) {
    height: 95px;
    padding-right: 20px;
    background-position: 38%;
  }
}

.HalloweenBanner_rightSideContainer__DNyZo {
  display: flex;
  align-items: center;
}

.HalloweenBanner_pumpkin__5pIiP {
  position: absolute;
  width: 40px;
  height: 40px;
  rotate: 27deg;
  top: 2px;
  right: -10px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
    top: -9px;
    left: -30px;
  }

  @media (min-width: 1024px) {
    width: 110px;
    height: 110px;
    top: -24px;
  }
}

.HalloweenBanner_pumpkinDesktopContainer__ogh_Z {
  display: none;

  @media (min-width: 1440px) {
    display: block;
    position: relative;
  }
}

.HalloweenBanner_pumpkinBackground__GzvG_ {
  display: none;
  position: absolute;
  left: 0;
  top: 0;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1024px) {
    scale: 1.3;
    top: 4px;
    left: 12px;
  }
}

.HalloweenBanner_pumpkinDesktop__YX2y6 {
  position: absolute;
  width: 90px;
  height: auto;
  transform: scaleY(-1) rotate(195deg);
  right: 30px;
}

.HalloweenBanner_mainText__tcHxb {
  font-size: 10px;
  font-weight: 500;
  position: relative;
  padding-left: 6px;

  @media (min-width: 768px) {
    font-size: 16px;
    padding-left: 54px;
    letter-spacing: -0.3px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    padding-left: 100px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    padding-left: 86px;
  }
}

.HalloweenBanner_mainTextDelimeter__2x6uY {
  @media (min-width: 768px) {
    display: none;
  }
}

.HalloweenBanner_subText__7ohdi {
  display: none;
  font-weight: 400;

  @media (min-width: 768px) {
    margin-top: 8px;
    display: block;
    font-size: 12px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
  }
}

.HalloweenBanner_timer__G3xBO {
  display: flex;
  gap: 8px;

  @media (min-width: 768px) {
    gap: 14px;
  }

  @media (min-width: 1024px) {
    gap: 30px;
  }

  @media (min-width: 1440px) {
    margin-right: 80px;
  }
}

.HalloweenBanner_timerNumber__EoWFZ {
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
  }
}

.HalloweenBanner_timerText__DQhTd {
  font-size: 6px;
  opacity: 0.6;

  @media (min-width: 768px) {
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }
}

.HalloweenBanner_timerNuberContainer__3z6LH {
  @media (min-width: 768px) {
    display: grid;
    grid-gap: 9px;
    gap: 9px;
  }
}

.HalloweenBanner_getDiscountButton__Gtjj6 {
  background: var(--beta);
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 8px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  @media (min-width: 768px) {
    padding: 14px 19px;
    font-size: 12px;
    margin-left: 27px;
    margin-right: 21px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    padding: 13px 22px;
    margin-left: 35px;
    margin-right: 31px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
    padding: 18px 40px;
    margin-left: 20px;
    margin-right: 35px;
  }
}

.HalloweenBanner_getDiscountButton__Gtjj6:hover {
  background: #005bb5;
}

.HalloweenBanner_closeButton__H8GRj {
  background: rgba(255, 255, 255, 0.2);
  color: black;
  font-size: 6px;
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 10px;
    width: 16px;
    height: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }
}

.HalloweenBanner_closeButton__H8GRj:hover {
  background: rgba(255, 255, 255, 0.3);
}

.BlackFridayBanner_BlackFridayBannerWrapper__02ucq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: black;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  height: 56px;
  overflow: hidden;
  position: relative;
  background-image: url(/_next/static/media/background.967b6494.svg);
  background-size: cover;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 9999;

  @media (min-width: 768px) {
    height: 76px;
    padding-right: 26px;
    background-repeat: no-repeat;
    background-position: -80%;
    background-size: auto;
  }

  @media (min-width: 1024px) {
    height: 90px;
    padding-right: 15px;
    background-position: 500%;
  }

  @media (min-width: 1440px) {
    height: 104px;
    padding-right: 20px;
    background-position: 28%;
  }
}

.BlackFridayBanner_rightSideContainer__47Goq {
  display: flex;
  align-items: center;
}

.BlackFridayBanner_starDiscountDesktopContainer__OJns6 {
  display: none;

  @media (min-width: 1440px) {
    display: block;
    position: relative;
  }
}

.BlackFridayBanner_starDiscount__GqWls {
  display: none;
  position: absolute;

  @media (min-width: 768px) {
    display: block;
    width: 88px;
    height: auto;
    top: 12px;
    left: -30px;
  }

  @media (min-width: 1024px) {
    top: 11px;
    left: -37px;
    width: 110px;
  }

  @media (min-width: 1440px) {
    top: 8px;
    left: -47px;
    width: 133px;
  }
}

.BlackFridayBanner_starDiscountDesktop__nJUhh {
  position: absolute;
  width: 104px;
  height: auto;
  right: -45px;
  top: -85px;
}

.BlackFridayBanner_mainText__pQ2XY {
  font-size: 10px;
  font-weight: 500;
  position: relative;
  padding-left: 6px;

  @media (min-width: 768px) {
    font-size: 16px;
    padding-left: 54px;
    letter-spacing: -0.3px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    padding-left: 100px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    padding-left: 86px;
  }
}

.BlackFridayBanner_mainTextDelimeterFirst__pAHd1 {
  @media (min-width: 1024px) {
    display: none;
  }
}

.BlackFridayBanner_mainTextDelimeterSecond___2flj {
  @media (min-width: 768px) {
    display: none;
  }
}

.BlackFridayBanner_subText__olWt0 {
  display: none;

  @media (min-width: 1024px) {
    margin-top: 8px;
    display: block;
    font-size: 14px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
    margin-top: 15px;
  }
}

.BlackFridayBanner_timer__VJe7b {
  display: flex;
  gap: 5px;

  @media (min-width: 768px) {
    gap: 14px;
    margin-right: 29px;
  }

  @media (min-width: 1024px) {
    gap: 18px;
    margin-right: 0;
  }

  @media (min-width: 1440px) {
    margin-right: 50px;
  }
}

.BlackFridayBanner_timerNumber__VSa66 {
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
  }
}

.BlackFridayBanner_timerText__sD2AV {
  font-size: 6px;
  opacity: 0.6;

  @media (min-width: 768px) {
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }
}

.BlackFridayBanner_timerNuberContainer__qVFHq {
  display: grid;
  grid-gap: 3px;
  gap: 3px;

  @media (min-width: 768px) {
    gap: 9px;
  }
}

.BlackFridayBanner_timerColon__5TiXp {
  margin-right: 3px;

  @media (min-width: 768px) {
    margin-right: 6px;
  }
}

.BlackFridayBanner_getDiscountButton__8Y2vb {
  background: var(--beta);
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 8px !important;
  padding: 10px 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;

  @media (min-width: 768px) {
    padding: 11px 32px !important;
    font-size: 12px !important;
    margin-left: 27px !important;
    margin-right: 21px !important;
  }

  @media (min-width: 1024px) {
    font-size: 14px !important;
    padding: 11px 22px !important;
    margin-left: 21px !important;
    margin-right: 22px !important;
  }

  @media (min-width: 1440px) {
    font-size: 16px !important;
    padding: 16px 40px !important;
    margin-left: 58px !important;
    margin-right: 35px !important;
  }
}

.BlackFridayBanner_getDiscountButton__8Y2vb:hover {
  background-color: var(--beta) !important;
}

.BlackFridayBanner_closeButton__smJFd {
  background: rgba(255, 255, 255, 0.2);
  color: black;
  font-size: 6px;
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 10px;
    width: 16px;
    height: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }
}

.BlackFridayBanner_closeButton__smJFd:hover {
  background: rgba(255, 255, 255, 0.3);
}

.BlackFridayBanner_ligthning__zOs_Z {
  width: 17px;
  height: auto;
  rotate: 199deg;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  @media (min-width: 1440px) {
    display: block;
    width: 23px;
  }
}

